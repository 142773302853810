import ConfigRoutes from "../../config/Routes"
import DashboardIndex from "../controllers/DashboardIndex";
import PermissionsIndex from "../controllers/PermissionsIndex";
import AdminsIndex from "../controllers/AdminsIndex";
import AdminsEdit from "../controllers/AdminsEdit";
import AdminsNew from "../controllers/AdminsNew";
import RoleIndex from "../controllers/RoleIndex";
import RoleEdit from "../controllers/RoleEdit";
import ProfileIndex from "../controllers/ProfileIndex";
import AdminMenuIndex from "../controllers/AdminMenuIndex";
import AdminMenuForm from "../controllers/AdminMenuForm";
import MediaIndex from "../controllers/MediaIndex";

export default class Routes extends ConfigRoutes{

    static routes = {
        "BackofficeDashboardIndex": DashboardIndex,
        "BackofficePermissionsIndex": PermissionsIndex,
        "BackofficeAdminsIndex": AdminsIndex,
        "BackofficeAdminsEdit": AdminsEdit,
        "BackofficeAdminsUpdate": AdminsEdit,
        "BackofficeAdminsNew": AdminsNew,
        "BackofficeAdminsCreate": AdminsNew,
        "BackofficeRolesIndex": RoleIndex,
        "BackofficeRolesEdit": RoleEdit,
        "BackofficeRolesNew": RoleEdit,
        "BackofficeRolesUpdate": RoleEdit,
        "BackofficeRolesCreate": RoleEdit,
        "BackofficeProfileIndex": ProfileIndex,
        "BackofficeAdminMenusIndex": AdminMenuIndex,
        "BackofficeAdminMenusNew": AdminMenuForm,
        "BackofficeAdminMenusCreate": AdminMenuForm,
        "BackofficeAdminMenusEdit": AdminMenuForm,
        "BackofficeAdminMenusUpdate": AdminMenuForm,
        "BackofficeMediaIndex": MediaIndex,
    };

    constructor() {
        super();
        this.routes = Object.assign(this.routes, Routes.routes);
    }
}